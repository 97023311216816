import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import { v5 as uuidv5 } from 'uuid';

const { useEffect, useState } = React;

const EDGEHOG_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZWRnZWhvZy5jbG91ZFwvYXBpXC92MVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2MjU1MzM1NjQsImV4cCI6MTc4MzIxMzU2NCwibmJmIjoxNjI1NTMzNTY0LCJqdGkiOiJoM3cxbE5oek13MHhwb0NvIiwic3ViIjo3MSwicHJ2IjoiNTgyMjZkMWYwOTk1ZmIzYmQyMDRjZmJjMjRiZjM2NGYzZjBjMzgwNCJ9.ZDIuU3xv6AFm10H5wAchKw-WLUgJqwAFZ7DBTNsLDEY';
const EDGEHOG_URL = 'https://edgehog.cloud/api/v1/production/gateways/hardware-id';
const DEVICE_ID_NAMESPACE = 'd4c0cc75-e48d-4a49-a7b4-88716f18f543';

const serialNumberRegex = /^K\d{8}$|^\d{9}$/i;

const charset = (() => {
  const newCharset = [];
  let baseCode;
  let i;

  baseCode = 'A'.charCodeAt(0);
  for (i = 0; i < 26; i += 1) {
    newCharset.push(String.fromCharCode(baseCode + i));
  }

  baseCode = 'a'.charCodeAt(0);
  for (i = 0; i < 26; i += 1) {
    newCharset.push(String.fromCharCode(baseCode + i));
  }

  baseCode = '0'.charCodeAt(0);
  for (i = 0; i < 10; i += 1) {
    newCharset.push(String.fromCharCode(baseCode + i));
  }

  newCharset.push('-');
  newCharset.push('_');

  return newCharset;
})();

const byteArrayToUrlSafeBase64 = (bytes: number[]) => {
  const binaryArray = bytes.map((b) => b.toString(2).padStart(8, '0'));
  const binaryString = binaryArray.join('').padEnd(132, '0');
  const octects = binaryString.match(/.{6}/g) || [];
  return octects.map((b) => charset[parseInt(b, 2)]).join('');
}

const getAstarteId = async (serial: string) => {
  const apiUrl = new URL(EDGEHOG_URL);
  apiUrl.search = new URLSearchParams({
    serial_number: serial,
  }).toString();

  const hardwareId = await axios({
    method: 'get',
    url: apiUrl.toString(),
    headers: {
      Authorization: `Bearer ${EDGEHOG_TOKEN}`,
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'appilication/json',
    },
  }).then(response => response.data.data.hardware_id);

  const macAddress = hardwareId.substring(4);
  const deviceUuid: string = uuidv5(macAddress, DEVICE_ID_NAMESPACE);

  if (!deviceUuid) {
    throw new Error("Couldn't generate device uuid");
  }

  const bytes = deviceUuid.replace(/-/g, '').match(/.{2}/g)!.map((b) => parseInt(b, 16));
  const deviceId = byteArrayToUrlSafeBase64(bytes);

  return deviceId;
};

function App() {
  const [serial, setSerial] = useState("");
  const [astarteDeviceID, setAstarteDeviceID] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const isValidSerial = serialNumberRegex.test(serial);

  useEffect(() => {
    if (isValidSerial) {
      setError(null);
      setLoading(true);
      getAstarteId(serial)
        .then((id) => {
          setAstarteDeviceID(id);
        })
        .catch(() => {
          setError("Serial number not found");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setAstarteDeviceID("");
      setLoading(false);
      setError(null);
    }
  }, [serial, isValidSerial]);

  return (
    <Container className="mt-4">
      <h1 className="text-center">S/N to Device ID</h1>
      <Form className="mt-4">
        <Row>
          <Col xs={5}>
            <Form.Group controlId="serialNumber">
              <Form.Label column>Serial Number</Form.Label>
              <Form.Control
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                isValid={serial.length > 0 && isValidSerial}
                isInvalid={serial.length > 0 && !isValidSerial}
              />
              <Form.Control.Feedback type="invalid">Invalid serial number</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={2} className="d-flex justify-content-center align-items-center">
            <FaArrowRight />
          </Col>
          <Col xs={5}>
            <Form.Group controlId="deviceId">
              <Form.Label column>
                Device ID
                {loading && <Spinner className="ml-2" animation="border" />}
              </Form.Label>
              <Form.Control value={astarteDeviceID} readOnly/>
            </Form.Group>
          </Col>
        </Row>
        {error && (
          <Row>
            <Col xs={12}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}

export default App;
